module.exports = {
	getEnvironment: () => process.env.REACT_APP_ENV || 'dev',
	isProductionEnv: () => process.env.REACT_APP_ENV === 'prod',
	graphQlEndpoint: () => `https://${domain.graphQl[ module.exports.getEnvironment() ]}`,
	apiEndpoint: ( endpoint ) => `https://${domain.api[ module.exports.getEnvironment() ]}${apiEndpoints[ endpoint ]}`,
	cloudfrontEndpoint: () => `https://${domain.cloudfront[ module.exports.getEnvironment() ]}`,
	SECURITY_URL: `${process.env.REACT_APP_HOME_URL}/security/`,
	PRIVACY_POLICY_URL: `${process.env.REACT_APP_HOME_URL}/privacy-policy/`,
	TERMS_OF_SERVICE_URL: `${process.env.REACT_APP_HOME_URL}/terms-of-service/`,
	HOME_UTM_SOURCE_CSP_URL: `${process.env.REACT_APP_HOME_URL}?utm_source=csp`,
	LOGIN_REFRESH: 5 * 60 * 1000, // 5 minutes
	LOGIN_REFRESH_MINIMUM_TIME: 20 * 1000, // 20 seconds
	FINANCE_REDIRECT_TIME: 10 * 1000, // 5 seconds
	PAYMENT_PLAN_NO_EMAIL_WARNING: ( totalAmount ) =>
		`Great news! If you need to break up your payment, your payment of ${totalAmount?.toLocaleString(
			'en-US',
			{ style: 'currency', currency: 'USD' },
		)} qualifies for a Payment Plan option. Unfortunately, this provider requests that you call them to set up a payment plan.`,
	PAYMENT_PLAN_MESSAGE: ( totalAmount ) =>
		`Great news! If you need to break up your payment, your payment of ${totalAmount?.toLocaleString(
			'en-US',
			{ style: 'currency', currency: 'USD' },
		)} qualifies for a Payment Plan option. Please fill out the terms below & select your payment method.`,

	publicKeyRaw: () => publicKey[ module.exports.getEnvironment() ],
};

// ❗ dev urls do not contain the region (use1) by design ❗
const domain = {
	graphQl: {
		demo: 'csp-graphql.use1.demo.be.payground.com/graphql',
		int: 'csp-graphql.use1.int.be.payground.com/graphql',
		dev: 'csp-graphql.dev.be.payground.com/graphql',
		prod: 'csp-graphql.use1.prod.be.payground.com/graphql',
		stage: 'csp-graphql.use1.stage.be.payground.com/graphql',
		pr01: 'csp-graphql.use1.pr01.be.payground.com/graphql',
		pr02: 'csp-graphql.use1.pr02.be.payground.com/graphql'
	},
	api: {
		demo: 'api.use1.demo.be.payground.com',
		int: 'api.use1.int.be.payground.com',
		dev: 'api.dev.be.payground.com',
		prod: 'api.use1.prod.be.payground.com',
		stage: 'api.use1.stage.be.payground.com',
		pr01: 'api.use1.pr01.be.payground.com',
		pr02: 'api.use1.pr02.be.payground.com'
	},
	cloudfront: {
		demo: 'd25ienhzet5u26.cloudfront.net',
		int: 'd25ienhzet5u26.cloudfront.net',
		dev: 'd25ienhzet5u26.cloudfront.net',
		prod: 'd25ienhzet5u26.cloudfront.net',
		stage: 'd25ienhzet5u26.cloudfront.net',
		pr01: 'd25ienhzet5u26.cloudfront.net',
		pr02: 'd25ienhzet5u26.cloudfront.net'
	},
};

const apiEndpoints = {
	verifyIdentity: '/auth/v1/token/quickPay',
};

const publicKey = {
	demo: '{"key_ops":["encrypt"],"ext":true,"kty":"RSA","n":"0SVsauiPgzoHpugkmSJ98vbwLj6ZYWgCfWu4gpQLiKIWEbGPXoxYD1beJmGAEHHaYa49s2IhxAVuXwt2MaOV8GWwXneiHuORayg1F13OdtCZRZmcq7JlNDXT-fxbLtZ5RpiNy9pcr_w7jySA76uppR8fTiDJqOSwkhII7rDmM8y7txp3xrr8UmMtaOQ9muzdImrrRGd6uUMEc2vEMrULG4rA5pYu3shEe0ioVdJCpO77_Z-RUTOdXSjEq5LO8A6i-XUA43_nSmHGE44jIvbwfDrptlGYvWKqu0Sfv0Bs2DNFx1B_AiS8YUWCBaSDf2andd7JrNUxLDZ1kb1UA1dvTQ","e":"AQAB","alg":"RSA-OAEP-256"}',
	int: '{"key_ops":["encrypt"],"ext":true,"kty":"RSA","n":"ha3tgiGG8CZOmYUGekSS2sIRqxMhssOYrp8b-eHzdlC2qCp0M9wstTXZ5HPs8nigRf_-M6ygeVnkyyp98qHjdz2BHzo63bdJc_lHeLGWvRM3Q5rLUszOa9ezzXImR40Z7BujUW6HCHQg8PGQ7e0-8nx4XO8eeuu6pRTqZZz-MgqRdNTe2MwtDn1L5QCdOCZIgC-fjTg0PZYoW1N6qJunOpqNROgiO5F1zFTBKgMSsckz-j_Xa8bCSLnU3vR8iYKZfy20j18LUU3Af0vU7xRVEM9xaQCx6o6nOn1e9CCqd0IU6xX8ThjipEEVCymghRw8doB1NISJuEMXuPpn0TC4cw","e":"AQAB","alg":"RSA-OAEP-256"}',
	dev: '{"key_ops":["encrypt"],"ext":true,"kty":"RSA","n":"zhPlvRqAkAU_x2fCJv2RHDj4La37TBIYBDEBJ1HjYcbiHsmvRK54YHp93-61fFEy3tFzIp7L1QYNMBVtU4-31m53DBQrRmG6P4yvNvkHaEdEvvuyBRf67UTEjMT2soPcStBhgHtr_AaR7bGMvLo02mPyHldmqNlP_FcCLguuRHhuf1FvaV5CCub3aM2qD-iav0vKzKnguxdVitY5gsr9YVY3WPQSyImqSRhyA2q3xcd_BXG457MPbsSixuAh-G6nyCMGC5mexdL4y9JP9isY0LChZAaVQKTtStK3V4R6NAQSk0EmbROyih-2xYkj-KLJHgl9Tx28dw2A7AxEwdtUeQ","e":"AQAB","alg":"RSA-OAEP-256"}',
	prod: '{"key_ops":["encrypt"],"ext":true,"kty":"RSA","n":"yMrd8_nrflFLKykp_yJTgIXmdvCxLxTb9fqlpP5VBynh8CtTRS9MJSsz9mcXuCsm6sagDb0-vfdOSEPzfmS57szx6n3KK6nZDlDSGGeeFvpyAE0mXXm33QShq4eQmPT2wyc9Qtbkl0KgsduQSZ7OqLHnhugSjQHJB2TCfL4wvMtVjv5RDnyw-WaMkooXnUuqTY_zx1DBl3mfkHl8JuG17Yd6msNCzP2TI2bg5flxS-I14fm1X-pFnNmh9udWQucQGW8Xk1FEOeBS2azYPtPTAnGaqn5DSYoyWqoLBkgWwNFIGZ5uC7H4T-lB3mTOpx1avapaYNWMGWkds7a9F7tqlQ","e":"AQAB","alg":"RSA-OAEP-256"}',
	stage: '{"key_ops":["encrypt"],"ext":true,"kty":"RSA","n":"x8swVi3xhKTAXkd_7OyLJjrYjfgtgCruoMQAoTGiHn56BqMb0t21H2P_rFLy52axNds_C1Dcfnf6Upm_wfI6QSPsm_yyMya7XvJ7yC_IIUTRzIaK8_8PUQtPCNiFshhtTUAvTb7qIan-DT7mQeDVhBqBhVpf8aOK3gaF8gXN5NnkbtbnfaZZ_42Q8AXsGd6Z-6eH90wbtfjrSOrGuygr2EUqMnvRBrJ08_NyPzk6yzAYf0TxoGNS8f2vrJzEI_V7Gv1GLPAp5w92p-BuCGy9WJUVrJ7jr4Qbzzt1EFj_gNOrRfKr0TIYmEc_SFsohI3Qud5rq5orkXcocg8l3yPYnQ","e":"AQAB","alg":"RSA-OAEP-256"}',
	pr01: '-----BEGIN PUBLIC KEY-----\nMIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEA0XAMHdooJw/nr84Zu9xe\n8dkypRH8CRDWq6bef9BUF3gWDb0lzZt7plqnLN8Hsr1bPduToXzDiiquz7Poifq1\nRKwaO9gAeY8dNJExijfgFUe5zdXcqsivqEqkWELaLM5b1go1vpiyeOJHzKrFoIsJ\npubgXGOG8K1+fk3lac560n0k+A7YconCCXBn9MheMGykCSq2krvdb9fEe+Kki/rS\n0Rv1EQiIz1tz5BUDamZV3SzlIbx7rSHoHyUD+qJb74Pk99P8hu6xTOtgxAT7+Ns4\n7wNNfBaUOCreYvnyTzZ9857AMDVbBfMECVsOPVtdISPcRWJh/1v6p3+HrSFz1NX7\nb8mulliDmRhwzpAWbj4uEGNVv3ZNgo6erLX5gPDtdgp8UJexDoqP6DdAYIBrRvHW\nVW3nVflf2dNA/y3JCgKH98ptc2bdjgiBamxDJAcPcIK6S5ulKhlOfCIK1XBRFxe5\nbzdBnTOs9/qClOa8iCy116QU90f52elLVirQQpjchicpjKnv9Ocf7+bEDOKHMkdJ\nyffAM87UOx3R58tTIByaOIg+dpDKO9oiyqWMN7Q2AMkw7J6wM0s+b9R6d3AQWKzD\nKIWSFDPOM82XEZkIcIUrBIWGoitpyo/fr05BCYT93IecN/y0htWf4aOz15mTSTIW\necO1cD+uxI35iqB0Apak68MCAwEAAQ==\n-----END PUBLIC KEY-----\n',
	pr02: '-----BEGIN PUBLIC KEY-----\nMIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEA0XAMHdooJw/nr84Zu9xe\n8dkypRH8CRDWq6bef9BUF3gWDb0lzZt7plqnLN8Hsr1bPduToXzDiiquz7Poifq1\nRKwaO9gAeY8dNJExijfgFUe5zdXcqsivqEqkWELaLM5b1go1vpiyeOJHzKrFoIsJ\npubgXGOG8K1+fk3lac560n0k+A7YconCCXBn9MheMGykCSq2krvdb9fEe+Kki/rS\n0Rv1EQiIz1tz5BUDamZV3SzlIbx7rSHoHyUD+qJb74Pk99P8hu6xTOtgxAT7+Ns4\n7wNNfBaUOCreYvnyTzZ9857AMDVbBfMECVsOPVtdISPcRWJh/1v6p3+HrSFz1NX7\nb8mulliDmRhwzpAWbj4uEGNVv3ZNgo6erLX5gPDtdgp8UJexDoqP6DdAYIBrRvHW\nVW3nVflf2dNA/y3JCgKH98ptc2bdjgiBamxDJAcPcIK6S5ulKhlOfCIK1XBRFxe5\nbzdBnTOs9/qClOa8iCy116QU90f52elLVirQQpjchicpjKnv9Ocf7+bEDOKHMkdJ\nyffAM87UOx3R58tTIByaOIg+dpDKO9oiyqWMN7Q2AMkw7J6wM0s+b9R6d3AQWKzD\nKIWSFDPOM82XEZkIcIUrBIWGoitpyo/fr05BCYT93IecN/y0htWf4aOz15mTSTIW\necO1cD+uxI35iqB0Apak68MCAwEAAQ==\n-----END PUBLIC KEY-----\n'
};
